<template>
  <b-table
    ref="table"
    class="lms-table manage-students scroll"
    id="table-manage-users"
    :items="users"
    :fields="fields()"
    primary-key="username"
    borderless
    responsive
    :tbody-transition-props="{ name: 'flip-list' }"
    stacked="sm"
    :no-local-sorting="true"
    :fixed="false"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :no-provider-paging="true"
    :current-page="currentPage"
    no-footer-sorting
    no-sort-reset
    @sort-changed="sortingChanged"
  >
    <template #thead-top="">
      <b-tr>
        <b-th colspan="6">
          <b-tr class="manage-students__upper-header">
            <div class="header-item header-item--active" @click="toggleSelectAll">
              <RadioButton
                :checked="allSelected"
                :isTypeCheckBox="true"
                size="sm"
                @change="toggleSelectAll"
              />

              <span> {{ $t('label.selectAll') }}</span>
            </div>
            <div
              class="header-item"
              :class="{ 'header-item--active': selected.length }"
              @click="groupCloseAccess"
            >
              <RedCrossCircleIcon :class="{ 'inactive-icon': !selected.length }" />
              <span>{{ $t('label.closeAccessToPrograms') }}</span>
            </div>
            <div
              class="header-item"
              :class="{ 'header-item--active': selected.length }"
              @click="groupOpenAccess"
            >
              <GreenFlagCircleIcon :class="{ 'inactive-icon': !selected.length }" />
              <span>{{ $t('label.openAccessToPrograms') }} </span>
            </div>
          </b-tr>
        </b-th>
      </b-tr>
    </template>

    <template #cell(selectUser)="data" class="select-user">
      <RadioButton
        :checked="isUserSelected(data.item._id)"
        :isTypeCheckBox="true"
        size="sm"
        @change="selectUser(data.item._id)"
      />
    </template>

    <template #cell(full_name)="data">
      <div :class="{ 'td-inactive': isBanned(data) }" @click="selectUser(data.item._id)">
        {{ data.item.full_name }}
      </div>
    </template>

    <template #cell(username)="data">
      <div :class="{ 'td-inactive': isBanned(data) }">
        {{ data.item.username }}
      </div>
    </template>

    <template #cell(registered_at)="data">
      <div :class="{ 'td-inactive': isBanned(data) }">
        {{ formatDateCreation(data.item.registered_at) }}
      </div>
    </template>

    <template #cell(last_active_at)="data">
      <div :class="{ 'td-inactive': isBanned(data) }">
        {{ formatDateLastActivity(data.item.last_active_at) }}
      </div>
    </template>

    <template #cell(programs_subscribed)="data">
      <div class="td-primary" :class="{ 'td-inactive': isBanned(data) }">
        {{ data.item.programs_subscribed }}
      </div>
    </template>

    <template #cell(programs_available)="data">
      <div class="td-primary" :class="{ 'td-inactive': isBanned(data) }">
        {{ data.item.programs_available }}
      </div>
    </template>

    <template #cell(access)="data">
      <LmsSwitch
        :value="!isBanned(data)"
        @update:value="(bool) => toggleUserAccess(bool, data.item._id)"
        :with-icon="true"
      />
    </template>

    <template #custom-foot>
      <tr role="row" ref="footer">
        <th colspan="8" class="table-footer" :class="{ 'table-footer--invisible': !loading }">
          <tr>
            <td>{{ $t('label.loading') }}</td>
          </tr>
        </th>
      </tr>
    </template>
  </b-table>
</template>

<script>
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import ru from 'javascript-time-ago/locale/ru.json';
import LmsSwitch from '@/components/LmsSwitch.vue';
import ActivityService from '@/services/activity.service';
import RadioButton from '@/components/Buttons/RadioButton.vue';
import RedCrossCircleIcon from '@/components/Icons/RedCrossCircleIcon.vue';
import GreenFlagCircleIcon from '@/components/Icons/GreenFlagCircleIcon.vue';
import { mapActions } from 'vuex';

TimeAgo.addLocale(ru);
TimeAgo.addLocale(en);

export default {
  name: 'TableManageUsers',
  components: {
    LmsSwitch,
    RadioButton,
    RedCrossCircleIcon,
    GreenFlagCircleIcon,
  },
  async created() {
    await this.getUsers({
      sortKey: this.sortBy,
      sortDirection: this.sortDirection,
      page: this.page,
      perPage: this.perPage,
    });

    this.$nextTick(() => {
      this.createObserverOfTableFooter(this.$refs.footer, () => {
        if (!this.isAllStudentsLoaded) {
          this.getMoreUsers();
        }
      });
    });
  },
  data() {
    return {
      sortBy: 'username',
      sortDesc: false,
      currentPage: 1,
      perPage: 15,
      selected: [],
      allSelected: false,
      users: [],
      isRequestBusy: false,
      observer: null,
      isAllStudentsLoaded: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    async getUsers(query) {
      this.loading = true;
      await ActivityService.getStudents(query)
        .then(({ data }) => {
          this.users = data;
          if (!this.users.length || this.perPage > data.length) {
            this.isAllStudentsLoaded = true;
          }
        })
        .catch(console.log);
      this.loading = false;
    },
    async getMoreUsers() {
      this.currentPage += 1;
      const query = {
        sortKey: this.sortBy,
        sortDirection: this.sortDirection,
        page: this.currentPage,
        perPage: this.perPage,
      };
      this.loading = true;
      await ActivityService.getStudents(query)
        .then(({ data }) => {
          this.users = [...this.users, ...data];
          if (!this.users.length || this.perPage > data.length) {
            this.isAllStudentsLoaded = true;
          }
        })
        .catch(console.log);
      this.loading = false;
    },
    toggleSelectAll() {
      if (this.selected.length === this.allSelectedUsersId.length) {
        this.selected = [];
      } else {
        this.selected = this.allSelectedUsersId;
      }
    },
    toggleUserAccess(bool, userId) {
      if (this.isRequestBusy) {
        return;
      }
      const prevState = [...this.users];
      this.rerenderBanStatus(bool, userId);
      if (bool) {
        ActivityService.unbanStudentsInAllPrograms([userId])
          .then(({ unbanned_students_ids: unbannedStudentsIds }) => {
            this.checkIsAllUnbanned(unbannedStudentsIds, 1, prevState);
            return null;
          })
          .catch(() => (this.users = prevState));
      } else {
        ActivityService.banStudentsInAllPrograms([userId]).catch(() => (this.users = prevState));
      }
    },
    async groupCloseAccess() {
      if (!this.selected.length) {
        return;
      }
      const onlyNotBannedIds = this.filterSelectedUsers(false);
      if (!onlyNotBannedIds.length) {
        return (this.selected = []);
      }

      const prevState = [...this.users];
      this.users = this.users.map((u) => {
        const clone = { ...u };
        if (onlyNotBannedIds.includes(clone._id)) {
          clone.programs_available = 0;
        }
        return clone;
      });

      this.selected = [];
      this.isRequestBusy = true;
      await ActivityService.banStudentsInAllPrograms(onlyNotBannedIds).catch(
        () => (this.users = prevState),
      );
      this.isRequestBusy = false;
    },
    async groupOpenAccess() {
      if (!this.selected.length) {
        return;
      }
      const onlyBannedIds = this.filterSelectedUsers(true);
      if (!onlyBannedIds.length) {
        return (this.selected = []);
      }

      const prevState = [...this.users];
      this.users = this.users.map((u) => {
        const clone = { ...u };
        if (onlyBannedIds.includes(clone._id)) {
          clone.programs_available = clone.programs_subscribed;
        }
        return clone;
      });

      this.selected = [];
      this.isRequestBusy = true;
      await ActivityService.unbanStudentsInAllPrograms(onlyBannedIds)
        .then(({ unbanned_students_ids: unbannedStudentsIds }) => {
          this.checkIsAllUnbanned(unbannedStudentsIds, onlyBannedIds.length, prevState);
          return null;
        })
        .catch(() => (this.users = prevState));
      this.isRequestBusy = false;
    },
    checkIsAllUnbanned(unbannedStudentsIds, idsSentCounter, prevUsers) {
      const isAllBanned = unbannedStudentsIds.length === idsSentCounter;
      if (!isAllBanned) {
        this.users = this.users.map((u, index) => {
          if (!unbannedStudentsIds.includes(u._id)) {
            u.programs_available = prevUsers[index].programs_available;
          }
          return u;
        });
        this.setWarningToastOfUnbanned();
      }
    },
    setWarningToastOfUnbanned() {
      this.setToaster({
        type: 'toast-warning',
        toast: {
          title: this.$t('header.attention'),
          body: this.$t('supportText.notAllWasUnbaned'),
        },
      });
    },
    selectUser(userId) {
      if (this.selected.includes(userId)) {
        this.selected = this.selected.filter((id) => id !== userId);
      } else {
        this.selected.push(userId);
      }
    },
    formatDateCreation(date) {
      const timeToSet = new Date(date);
      return new Intl.DateTimeFormat(this.$i18n?.locale === 'en' ? 'en' : 'ru', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(timeToSet);
    },
    formatDateLastActivity(date) {
      const timeToSet = new Date(date);
      const timeAgo = new TimeAgo(this.$i18n?.locale === 'en' ? 'en-US' : 'ru-Ru');
      const rawDate = timeAgo.format(timeToSet);
      return rawDate.charAt(0).toUpperCase() + rawDate.slice(1);
    },
    sortingChanged({ sortBy, sortDesc }) {
      this.getUsers({
        sortKey: sortBy,
        sortDirection: sortDesc ? 'descending' : 'ascending',
        page: 1,
        perPage: this.perPage * this.currentPage,
      });
    },
    createObserverOfTableFooter(element, callback) {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // if element is visible - triggers callback
            if (entry.intersectionRatio > 0) {
              callback();
            }
          });
        },
        {
          root: null,
          threshold: 1.0,
        },
      );

      this.observer.observe(element);
    },
    fields() {
      return [
        {
          key: 'selectUser',
          label: '',
          class: 'first-column',
        },
        {
          key: 'full_name',
          label: this.$t('label.user'),
          class: 'user-name-column',
          sortable: true,
        },
        {
          key: 'username',
          label: 'E-mail',
          class: 'email-column',
          sortable: true,
        },
        {
          key: 'registered_at',
          label: this.$t('label.added'),
          class: 'date-subscribe-column',
          sortable: true,
        },
        {
          key: 'last_active_at',
          label: this.$t('label.lastActivity'),
          class: 'last-activity-column',
          sortable: true,
        },
        {
          key: 'programs_subscribed',
          label: this.$t('label.totalPrograms'),
          class: 'total-programs-column',
          sortable: true,
        },
        {
          key: 'programs_available',
          label: this.$t('label.available'),
          class: 'available-programs-column',
          sortable: true,
        },
        {
          key: 'access',
          label: this.$t('label.access'),
          class: 'access-column',
        },
      ];
    },
    rerenderBanStatus(bool, userId) {
      this.users = this.users.map((u) => {
        const clone = { ...u };
        if (clone._id === userId) {
          // eslint-disable-next-line no-unused-expressions
          bool
            ? (clone.programs_available = clone.programs_subscribed)
            : (clone.programs_available = 0);
        }
        return clone;
      });
    },
    /**
     * filters users only by banned ones and vice versa
     * @param isBanned bool
     * @returns Array
     */
    filterSelectedUsers(isBanned) {
      return this.selected.reduce((filteredUsers, id) => {
        const userIndex = this.users.findIndex((u) => u._id === id);
        if (userIndex > -1) {
          if (isBanned) {
            if (+this.users[userIndex].programs_available === 0) {
              filteredUsers.push(this.users[userIndex]._id);
            }
          } else if (+this.users[userIndex].programs_available > 0) {
            filteredUsers.push(this.users[userIndex]._id);
          }
        }
        return filteredUsers;
      }, []);
    },
  },
  computed: {
    allSelectedUsersId() {
      return this.users.map((u) => u._id);
    },
    isBanned() {
      return (data) => +data.item.programs_available === 0;
    },
    isUserSelected() {
      return (userId) => this.selected.includes(userId);
    },
    sortDirection() {
      return this.sortDesc ? 'descending' : 'ascending';
    },
  },
  watch: {
    selected(newValue) {
      // Handle changes in individual checkboxes
      if (newValue.length === 0) {
        this.allSelected = false;
      } else this.allSelected = newValue.length === this.allSelectedUsersId.length;
    },
  },
  beforeDestroy() {
    if (this.observer?.unobserve) {
      this.observer.unobserve(this.$refs.footer);
    }
  },
};
</script>
