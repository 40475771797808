<!-- eslint-disable max-len -->
<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6667 7.38674V8.00007C14.6658 9.43769 14.2003 10.8365 13.3396 11.988C12.4788 13.1394 11.2689 13.9817 9.89023 14.3893C8.51162 14.797 7.03817 14.748 5.68964 14.2498C4.34112 13.7516 3.18976 12.8308 2.4073 11.6248C1.62484 10.4188 1.25319 8.99211 1.34778 7.55761C1.44237 6.12312 1.99813 4.75762 2.93218 3.66479C3.86623 2.57195 5.12852 1.81033 6.53079 1.4935C7.93306 1.17668 9.40017 1.32163 10.7133 1.90674"
      stroke="#1CE890"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.6667 2.66675L8 9.34008L6 7.34008"
      stroke="#1CE890"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'GreenFlagCircleIcon',
};
</script>
