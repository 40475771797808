<template>
  <div class="manage-students-page">
    <div key="content" class="manage-students-page__wrapper">
      <div class="manage-students-page__info-box info-box container-fluid">
        <div class="info-box__title">{{ $t('header.manageStudents') }}</div>
      </div>
      <div class="container-fluid">
        <div class="manage-students-page__content">
          <TableManageUsers />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableManageUsers from '@/components/TableManageUsers.vue';

export default {
  name: 'ManageStudents',
  components: {
    TableManageUsers,
  },
};
</script>
