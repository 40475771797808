<template>
  <b-form-checkbox
    class="lms-switch"
    :class="{ 'lms-switch--large': size === 'large', 'with-icon': withIcon }"
    v-model="computedValue"
    :key="key"
    :disabled="disabled"
    name="check-button"
    switch
  >
    <slot></slot>
  </b-form-checkbox>
</template>

<script>
export default {
  name: 'LmsSwitch',
  props: {
    // Must be use with v.sync
    value: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    updateKey: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    checked: false,
  }),
  computed: {
    key() {
      return this.updateKey ? this.value : null;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (newValue !== this.value) {
          this.$emit('update:value', newValue);
        }
      },
    },
  },
};
</script>
