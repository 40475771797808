<!-- eslint-disable max-len -->
<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8 1.33325C4.3181 1.33325 1.33334 4.31802 1.33334 7.99992C1.33334 11.6818 4.3181 14.6666 8 14.6666Z"
      stroke="#EB5757"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M10 6L6 10" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6 6L10 10" stroke="#EB5757" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'RedCrossCircleIcon',
};
</script>
